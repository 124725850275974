
























































































































import { Component, Vue } from 'vue-property-decorator'
import Copy16 from '@carbon/icons-vue/es/copy/16'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'
import { namespace } from 'vuex-class'
import { ValidationObserver } from 'vee-validate'

import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'

const sessionModule = namespace('session')

@Component({
  components: {
    Copy16,
    ValidationObserver,
    PasswordConfirmField
  }
})
export default class ServiceVistoryIndex extends Vue {
  @sessionModule.Getter isCommonUser!: boolean
  auditConfirmModal = false
  copiedModal = false
  vistoryToken = ''
  filteredData = []

  form: Record<string, any> = {
    confirm: '',
    errors: {
      confirm: ''
    }
  }

  created () {
    this.fetchVistories()
  }

  fetchVistories () {
    axios.get(`service/${this.$route.params.id}/vistory`)
      .then(response => {
        this.filteredData = camelCaseKeys(response.data.data)
      })
  }

  submitNewAudit () {
    axios.post(`service/${this.$route.params.id}/vistory`, this.form)
      .then(response => {
        const token = response.data.data.token

        this.setInputToken(token)
        if (!this.isCommonUser) {
          this.copyTokenInput()
        }
        this.toggleModal('auditConfirmModal')
      })
      .catch(err => this.handleSubmitError(err))
      .finally(() => {
        this.fetchVistories()
      })
  }

  handleSubmitError (err: any) {
    const response = err.response

    const errors = response.data.errors
    if (errors) {
      Object.entries(errors).map(([key, value]: [string, any]) => {
        this.form.errors[key] = value[0]
      })
    }
  }

  setInputToken (token: string) {
    this.vistoryToken = window.location.origin + this.$router.resolve({
      name: 'ServiceVistoryShow',
      params: {
        id: this.$route.params.id,
        hash: token
      }
    }).href
  }

  copyTokenInput () {
    Vue.nextTick()
      .then(() => {
        const tokenField = document.getElementById('token-field') as HTMLInputElement

        tokenField.select()
        tokenField.setSelectionRange(0, 255)

        document.execCommand('copy')

        this.toggleModal('copiedModal')
      })
  }

  toggleModal (modalName: string) {
    this.$data[modalName] = !this.$data[modalName]
  }

  // onPageChange () {
  //   console.log('Nova pagina')
  // }
}
